<template>
  <div class="driver">
    <!-- 主体内容 -->
    <div class="driver_cont">
      <div
        class="pattern cont"
        v-class="
          'pattern cont animate__animated animate__fadeInup animate__block'
        "
      >
        <div class="second">
          <span class="size36">企业责任</span>
          <p>Responsibility</p>
          <div>
            <div class="img">
              <img class="simg" src="../../assets/images/1.png" alt="" />
            </div>
            <div class="second_part">
              <div class="part">
                <span>免费服务 致敬抗疫一线医疗工作者</span>
                <div class="line1"></div>
                <p>
                  新冠疫情爆发后，为向广大抗疫一线工作者致敬，公司实施合肥市抗疫一线工作者免费预约出行服务政策，2020年2月11日起，合肥市一线的医疗工作者、政府机构工作人员、社区工作者均可申请并享受免费出行服务。活动圆满完成3名医务工作者和1名社区工作者的乘车服务工作，共计免费服务57单；其中2名医务工作者和1名社区工作者拍摄相关致谢视频对我公司提供的公益活动表示感谢。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <foot></foot> -->
    <!-- <foot :animate="false"></foot> -->
    <div class="box">
      <p>
        Copyright © 2018-2021 合肥和行科技有限公司 All Rights Reserved.
      </p>
      <p>皖公网安备 34019102000434号 皖ICP备18015830号</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.size36 {
  font-size: 0.52rem;
}
p {
  text-align: justify;
  line-height: 20px;
  margin-left: 1px;
  font-size: 0.26rem;
  
}
.animate__block {
  visibility: visible !important;
}
.animate__animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-delay: 0.2s;
}
.animate__fadeInup {
  -webkit-animation-name: FadeInup;
  animation-name: FadeInup;
}
.animate__animated {
  --animate-duration: 0.5s;
}
.animate__animated.animate__fadeIn {
  --animate-duration: 0.5s;
}
.driver {
  position: relative;
}
.driver_cont {
  margin-bottom: 0.5rem;
}
.cont {
  color: #2d2d39;
  margin-top: 0.9rem;
  visibility: hidden;
}
.cont .second {
  display: flex;
  flex-direction: column;
  margin-top: 1.2rem;
}
.pattern .second {
  padding: 0 0.48rem;
}
.pattern .second span {
  font-weight: bold;
  margin: 0.25rem 0 0.07rem;
}

.pattern .second .second_part {
  width: 98%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 0.05rem;
}
.pattern .second .second_part .part span {
  font-size: 0.3rem;
  margin: 0.13rem auto 0.13rem;
}
.pattern .second .second_part .part p {
  font-size: 0.26rem;
  color: #848494;
  margin: 0 0 0.47rem;
}
@keyframes FadeInup {
  0% {
    opacity: 0;
    -webkit-transform: translateY(30%);
    transform: translateY(30%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
.img {
  width: 98%;
  margin: 8% auto 0;
}
.simg {
  width: 100%;
  height: 100%;
}
.line {
  width: 98%;
  height: 1px;
  margin: 0 auto;
  background-color: #e3e3e9;
}
.line1 {
  width: 20%;
  height: 1px;
  margin: 7px 0 10px;
  background-color: #e3e3e9;
}
.box {
  width: 100%;
  padding: 0.46rem;
  box-sizing: border-box;
  background: #f5f5f5;
  position: fixed;
  bottom: 0;
  p {
    color: #848494;
    font-size: 0.18rem;
  }
  p:nth-child(2) {
    margin-top: 0.2rem;
  }
}
</style>